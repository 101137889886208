/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* AgGrid CSS files */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

/* font-awesome icons */
@import "@fortawesome/fontawesome-free/css/all.css";

.md {
    --ion-header-height: 56px;
    --ion-footer-height: 56px;    
}

.ios {
    --ion-header-height: 44px;
    --ion-footer-height: 56px;
}

html{
    height: -webkit-fill-available;
}

body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
}

app-home {
    .main-body {
        &.scale {
            transform-origin: 0 0;
            transform: scale(var(--body-scale-level, 1));
            width: var(--body-scale-width, 100%);
            height: var(--body-scale-height, 100%);      
        }

        &.zoom {
            zoom: var(--body-zoom-level, 100%);
            -webkit-text-size-adjust: var(--body-zoom-level, 100%);
            width: var(--body-zoom-width);
            height: var(--body-zoom-height);
        }
    }

    [hidden]{
        transform: none;
    }      
}

ion-content {
    --overflow: hidden;
}

// scrollable without scrollbar
.no-scrollbar-y {
    box-sizing: content-box;
    background: var(--ion-background-color);

    max-height: 100%;
    height: 100%;
    overflow-x: hidden;    

    &.show {
        overflow-y: auto !important;
        margin: 0px !important;
        padding: 0px !important;

        &::-webkit-scrollbar {
            width: 10px !important;
        }
    
        &::-webkit-scrollbar-track {
            background-color: var(--ion-background-color);
        }    
    
        &::-webkit-scrollbar-thumb {
            background-color: var(--ion-color-medium);
            border-radius: 5px;
        }    
    }

    // https://www.geeksforgeeks.org/hide-scroll-bar-but-while-still-being-able-to-scroll-using-css/
    overflow-y: -moz-scrollbars-none;
    -ms-overflow-style: none; 
    &::-webkit-scrollbar { 
        width: 0 !important 
    }
    
    margin: 0px -30px 0px 0px;
    padding: 0px 30px 0px 0px;
    z-index: 0;

    &.mobile {  /* adjust for mobile devices */
        width: 100%;
        overflow-y: auto;
        margin-right: 0px;
        padding: 0px !important;
    }  
}

.no-scrollbar-x {
    box-sizing: content-box;
    background: var(--ion-background-color);

    max-width: 100%;
    width: 100%;
    overflow-y: hidden;    

    &.show {
        overflow-x: auto !important;
        margin: 0px !important;
        padding: 0px !important;

        &::-webkit-scrollbar {
            width: 10px !important;
        }
    
        &::-webkit-scrollbar-track {
            background-color: var(--ion-background-color);
        }    
    
        &::-webkit-scrollbar-thumb {
            background-color: var(--ion-color-medium);
            border-radius: 5px;
        }    
    }

    // https://www.geeksforgeeks.org/hide-scroll-bar-but-while-still-being-able-to-scroll-using-css/
    overflow-x: -moz-scrollbars-none;
    -ms-overflow-style: none; 
    &::-webkit-scrollbar { 
        width: 0 !important 
    }
    
    z-index: 0;

    &.mobile {  /* adjust for mobile devices */
        width: 100%;
        overflow-x: auto;
        margin-right: 0px;
        padding: 0px !important;
    }  
}

.no-select, .no-select * {
    user-select: none;              /* supported by Chrome and Opera */
    -webkit-user-select: none;      /* Safari */
    -khtml-user-select: none;       /* Konqueror HTML */
    -moz-user-select: none;         /* Firefox */
    -ms-user-select: none;          /* Internet Explorer/Edge */
}